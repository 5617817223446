<template>
  <vx-card :title="title">
    <div class="vx-row mb-2" style="width: 50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="shipmentDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.shipmentDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="territory.selected"
          :options="territory.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Warehouse</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="warehouse.selected"
          :options="warehouse.options"
          placeholder="Type to search"
          track-by="warehouse_name"
          label="warehouse_name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.warehouse_code }}
                {{ props.option.warehouse_name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.warehouse_code }}
                {{ props.option.warehouse_name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span></span>
      </div>
      <div class="vx-col sm:w-3/4 w-full whitespace-no-wrap">
        <vs-button @click="getData" color="primary">Filter</vs-button>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div class="btn-group ml-5">
              <vs-button
                size="small"
                color="success"
                type="border"
                @click="handleExport('excel')"
                >Excel</vs-button
              >
              <!-- <vs-button size="small" color="primary" type="border" @click="handleExport('pdf')">PDF</vs-button> -->
            </div>
          </template>
          <template slot="thead">
            <vs-th sort-key="date">Shipment Date</vs-th>
            <vs-th sort-key="item_code">Shipment No</vs-th>
            <vs-th sort-key="value">Status Shipment</vs-th>
            <vs-th sort-key="item_name">Jumlah PI</vs-th>
            <vs-th sort-key="item_name">Jumlah Customer</vs-th>
            <vs-th sort-key="item_name">Ownership</vs-th>
            <vs-th sort-key="item_name">Driver Type</vs-th>
            <vs-th sort-key="item_name">Trans Plan Point</vs-th>
            <vs-th sort-key="item_name">Driver Name</vs-th>
            <vs-th sort-key="item_name">Vehicle No</vs-th>
            <vs-th sort-key="item_name">Vehicle Type</vs-th>
            <vs-th sort-key="item_name">Driver/Vendor Code</vs-th>
            <vs-th sort-key="item_name">Driver/Vendor Name</vs-th>
            <vs-th sort-key="item_name">KM Berangkat</vs-th>
            <vs-th sort-key="item_name">KM Kembali</vs-th>
            <vs-th sort-key="item_name">Distance</vs-th>
            <vs-th sort-key="item_name">Distance UOM</vs-th>
            <vs-th sort-key="item_name">Shipment Cost No</vs-th>
            <vs-th sort-key="item_name">Shipment Cost Value</vs-th>
            <vs-th sort-key="item_name">Cash Out Doc No(Odoo)</vs-th>
            <vs-th sort-key="item_name">Cash Out Date (Odoo)</vs-th>
            <vs-th sort-key="item_name">Cash Out Value (Odoo)</vs-th>
            <vs-th sort-key="item_name">Biaya Kuli</vs-th>
            <vs-th sort-key="item_name">Biaya Parkir</vs-th>
            <vs-th sort-key="item_name">Biaya Keamanan</vs-th>
            <vs-th sort-key="item_name">Retribusi</vs-th>
            <vs-th sort-key="item_name">Biaya Toll</vs-th>
            <vs-th sort-key="item_name">BBM</vs-th>
            <vs-th sort-key="item_name">Biaya Lain-lain</vs-th>
            <vs-th sort-key="item_name">Nilai Realisasi Shipemnt Cost</vs-th>
            <vs-th sort-key="item_name">Refund</vs-th>
            <vs-th sort-key="item_name">No Doc Realisasi (Odoo)</vs-th>
            <vs-th sort-key="item_name">Status Realisasi Shipment Cost</vs-th>
          </template>

          <template v-for="(dt, index) in table.data">
            <vs-tr :key="index">
              <vs-td>{{ dt.shipment_date }}</vs-td>
              <vs-td>{{ dt.shipment_number }}</vs-td>
              <vs-td>{{ dt.shipment_status }}</vs-td>
              <vs-td>{{ dt.jml_do }}</vs-td>
              <vs-td>{{ dt.jml_customer }}</vs-td>
              <vs-td>{{ dt.ownership }}</vs-td>
              <vs-td>{{ dt.shipment_type }}</vs-td>
              <vs-td>{{ dt.trans_plan_point }}</vs-td>
              <vs-td>{{ dt.driver_name }}</vs-td>
              <vs-td>{{ dt.vehicle_number }}</vs-td>
              <vs-td>{{ dt.ship_type_desc }}</vs-td>
              <vs-td>{{ dt.fwd_agent_no }}</vs-td>
              <vs-td>{{ dt.fwd_agent_name }}</vs-td>
              <vs-td>{{ dt.km_berangkat }}</vs-td>
              <vs-td>{{ dt.km_kembali }}</vs-td>
              <vs-td>{{ dt.distance }}</vs-td>
              <vs-td>{{ "KM" }}</vs-td>
              <vs-td>{{ dt.shipment_cost_no }}</vs-td>
              <vs-td>{{ dt.shipment_cost_value }}</vs-td>
              <vs-td>{{ dt.cash_out_doc_no }}</vs-td>
              <vs-td>{{ dt.cash_out_doc_date }}</vs-td>
              <vs-td>{{ dt.cash_out_doc_value }}</vs-td>
              <vs-td>{{ dt.biaya_kuli }}</vs-td>
              <vs-td>{{ dt.biaya_parkir }}</vs-td>
              <vs-td>{{ dt.biaya_keamanan }}</vs-td>
              <vs-td>{{ dt.biaya_retribusi }}</vs-td>
              <vs-td>{{ dt.biaya_toll }}</vs-td>
              <vs-td>{{ dt.biaya_bbm }}</vs-td>
              <vs-td>{{ dt.biaya_other }}</vs-td>
              <vs-td>{{ dt.nilai_realisasi_shipment_cost }}</vs-td>
              <vs-td>{{ dt.refund }}</vs-td>
              <vs-td>{{ dt.no_doc_realisasi_odoo }}</vs-td>
              <vs-td>{{ dt.status_shipment }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      title: "Shipment Planning",
      baseUrl: "/api/v1/report/shipment-planning",
      table: this.tableState(),
      territory: {
        selected: null,
        options: [],
      },
      warehouse: {
        selected: null,
        options: [],
      },
      warehouseID: null,
      warehouseCode: "",
      shipmentDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).utcOffset(0).format("YYYY-MM-DD");
      }
    },
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "";
        this.table.sort = "";
      }
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
      this.action = "";
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            date_from: this.dateFormat(this.shipmentDate.startDate),
            date_to: this.dateFormat(this.shipmentDate.endDate),
            warehouse_id: this.warehouseID,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getAllDataTerritoryArea() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            if (this.territory.options.length > 0) {
              this.territory.selected = this.territory.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getAllDataWarehouseByTerritory(idx) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouse-territory", {
          params: {
            order: "id",
            sort: "asc",
            territory_id: idx,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.warehouse.options = resp.data.records;
            if (this.warehouse.options.length > 0) {
              this.warehouse.selected = this.warehouse.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    selectedTerritory() {
      console.log(this.territory.selected);

      this.warehouse.options = [];
      this.territoryCode = this.territory.selected.code;
      this.getAllDataWarehouseByTerritory();
    },
    handleExport(file) {
      // this.$vs.loading();
      this.$http
        .get("/api/v1/report/shipment-planning-export", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.warehouseID,
            date_from: this.dateFormat(this.shipmentDate.startDate),
            date_to: this.dateFormat(this.shipmentDate.endDate),
            file: file,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          var fileURL = window.URL.createObjectURL(new Blob([resp]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          if (file == "excel") {
            fileLink.setAttribute(
              "download",
              this.warehouseCode + "_shipment_planning.xlsx"
            );
          } else {
            fileLink.setAttribute(
              "download",
              this.warehouseCode + "_shipment_planning.pdf"
            );
          }
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
  mounted() {
    this.getAllDataTerritoryArea();
  },
  watch: {
    "territory.selected": function (v) {
      if (v) {
        this.getAllDataWarehouseByTerritory(v.id);
      } else {
        this.table = this.tableState();
      }
    },
    "warehouse.selected": function (v) {
      if (v) {
        this.warehouseID = v.warehouse_id;
        this.warehouseCode = v.warehouse_code;
        this.getData();
      } else {
        this.table = this.tableState();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        if (val != this.table.page) {
          this.handleChangePage(val);
        }
      },
    },
  },
};
</script>
